import React, { useEffect, useState }  from 'react';
import './App.css';
import { RequireAuth, useAuthUser, useIsAuthenticated } from 'react-auth-kit'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AllRoutes } from './routes/routes';
import { NavBarMenu } from './components/navBarMenu';
import initApp from './actions/init';
import { connect } from 'react-redux';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firebase } from './global/firebase';

declare global {
  interface Window {
    multilocationsmsnotification:boolean;
  }
}
function onlyUnique(value:any, index:any, array:any) {
  return array.indexOf(value) === index;
}
const  App=(datar:any)=> {
const Isauthenticate =useIsAuthenticated();
const [init,setinit]=useState(false);
const AllRoute=AllRoutes();
const [bool,setbool]=useState(false)
const [countreads,setcountreads]=useState('' as any)
const auth:any = useAuthUser();
  useEffect(()=>{  
    try {


  if(!window.location.href.includes('/just-in/')){

   let check=  (datar.BusinessesReducer.Businesses.length+datar.BusinessesReducer.Archived.length);
   let finalLen =datar.BusinessesReducer.len;
   if(Isauthenticate()){
    
    const unique = auth().accountData.permissions.businesses.filter(onlyUnique);
    finalLen = auth().accountData.permissions.role!=='admin' && unique.length>0 ? unique.length : finalLen
    check = auth().accountData.permissions.role!=='admin' && unique.length>0 ? datar.BusinessesReducer.Businesses.length : check
   }
   
    if(check===finalLen && datar.BusinessesReducer.fetching===false){
      console.log(finalLen,check);

      if(!window.location.href.includes('/just-in/')){
      const el:any =document.querySelector('.App')             
        if(el!==undefined && el!==null){
        el.style.marginTop='51px'
        }
      } 

        // try {
  
        //   const path =window.location.pathname.split('/')[2]
          
        //   getDocs(query(collection(firebase,'businesses'),where('path','==',path))).then((r)=>{
        //   const rr =r.docs[0].data();
        //   if(rr.multilocationsmsnotification!==undefined && rr.multilocationsmsnotification===true){
        //     window.multilocationsmsnotification=true;
        //   }else{
        //     window.multilocationsmsnotification=false
        //   }

        const day = new Date().getDay().toString();     
         
        setInterval( () =>{
          const reads = localStorage.getItem(day)!==null ? localStorage.getItem(day)  :''
          setcountreads(reads);console.log(reads); }, 5000);
       setinit(true); 
        //  });
          
        // } catch (error) {
        //     console.log(error);
        //     window.multilocationsmsnotification=false;

        // }

  
      
    }else{
     setbool(!bool)
    }


  }  else{
    setinit(true); 
  }  

  } catch (error) {
      console.log(error);
      
  }

  },[datar.BusinessesReducer.fetching,bool])
  
    if(!Isauthenticate() && window.location.href.split('/').pop()!==null && window.location.href.split('/').pop()!=='' && !window.location.href.includes('/just-in/') && !window.location.href.includes('/unsubscribe-from-reports')){
    window.location.href = '/'
    }

    
  return (
    <div className="App">
      
        {/* {<div className='positionofreaddocs'>Today Reads Left {countreads}</div>} */}
      
   <BrowserRouter>  
  {Isauthenticate() && (!window.location.href.includes('LocationCouponData') && !window.location.href.includes('customerInfo'))? <NavBarMenu /> :'' }
            <Routes>
    {init ? AllRoute.map((r:any,index:any)=>(  
      <Route key={index} path={r.path} element={
         r.auth ? 
         <RequireAuth  loginPath={'/'} >
          {r.component}
         </RequireAuth>:
        r.component
       }
    />
    )): 'Loading...'
   }       
      </Routes> 
    </BrowserRouter>

    </div>
  );
}


const mapStateToProps = (state:any) => ({
  ...state
});


export default connect(mapStateToProps,initApp())(App);
