import { Cell, Column, RenderMode, SelectionModes, Table as TableBase, TableLoadingOption, Utils} from "@blueprintjs/table"
import { range } from "lodash";
import React, { useEffect, useState }  from 'react';
import { Users } from "../../../interfaces/users";
import { Timestamp, collection, getDocs, query, where } from "firebase/firestore";
import format from "date-fns/format";
import {  Icon } from "@blueprintjs/core";
import { firebase } from "../../../global/firebase";
import useAuthUser from "react-auth-kit/dist/hooks/useAuthUser";
import { substractRead } from "../../../global/documentreadcount";
export const CheckinsTable=(data:any)=>{
  
    const [numColumns,setnumColumns]=useState(0)
    const [numRows,setnumRows]=useState(data.numRows)
    const [fontSize,setfontSize]=useState(data.fontSize)
    const [columns,setcolumns]=useState([] as any)
    const [bannedCusotmerTableRowBgColor,setbannedCusotmerTableRowBgColor]=useState('#fff')
    const [bannedCusotmerTableRowFontColor,setbannedCusotmerTableRowFontColor]=useState('#000')
    const [tableBackgroundColor,settableBackgroundColor]=useState('#fff')
    const [tableFontColor,settableFontColor]=useState('#000')
    const [tableBorderColor,settableBorderColor]=useState('#000')
    const [vehiclePickVal,setvehiclePickVal]=useState([] as any)
    const [picks,setpicks]=useState([] as any)
    const [finalCheckins,setfinalCheckins]=useState(data.checkIns)
    const [coupons,setcoupons]=useState([] as any)
    const loginUser =useAuthUser()
    // const rowHeights = () =>{ range(0, data.numRows).map(() => fontSize * 7.5)};

    useEffect(()=>{
      subscribeToCoupons(data.checkIns)
        setnumRows(data.numRows)
        setfontSize(data.fontSize)  
        setfinalCheckins(data.checkIns)    
        getPicksAll(data.checkIns)
        settableBorderColor(data.TableStyle.tableBorderColor)
        settableFontColor(data.TableStyle.tableFontColor)
        settableBackgroundColor(data.TableStyle.tableBackgroundColor)
        const elel:any =  document.getElementsByClassName('bp5-table-column-headers')[0];
        if(elel!==undefined && elel!==null){
if(data.business!==undefined && data.business.darkmode!==undefined && data.business.darkmode===true)     {
  elel.style.background='rgb(92, 112, 128)'
        elel.style.color='#fff'
} else{
  elel.style.background=data.TableStyle.tableBackgroundColor
        elel.style.color=data.TableStyle.tableFontColor
}    
      
        }

        if(data.business.checkInsOptions!==undefined && data.business.checkInsOptions.bannedCusotmerTableRowBgColor!==undefined){
          setbannedCusotmerTableRowBgColor(data.business.checkInsOptions.bannedCusotmerTableRowBgColor)
         }
         if(data.business.checkInsOptions!==undefined && data.business.checkInsOptions.bannedCusotmerTableRowFontColor!==undefined){
          setbannedCusotmerTableRowFontColor(data.business.checkInsOptions.bannedCusotmerTableRowFontColor)
         }
    },[data.checkIns])


    const  subscribeToCoupons = (checkIns: Users.CheckIn[]) => {
      // console.log(rowIndex);
      const { businessId } = data.business;
      if (checkIns.length > 0) {
        const couponsx: any = [];
        checkIns.forEach((ele) => {
          const quer =getDocs(query(collection(firebase,`users/${ele.userId}/coupons`)))

  
          const couponDta = quer;
          let i = 0;
          couponDta.then((dt) => {
            dt.forEach((doc) => {
              substractRead(1)
              const couponId = doc.id;
              const couponData:any = doc.data() as Users.Coupon;
              if (couponData) {
                const { redemption_flag } = couponData;
  
                if (redemption_flag === false && businessId === couponData.businessId) {
                  // couponsx.push({ couponId, ...couponData });

                  if (couponData.expires_date && couponData.expires_date[`seconds`] > Math.floor(Date.now() / 1000)) {
                    i++;
                  }
                }
              }
            });
            // this.state.coupons[ele.userId] = i;
            couponsx[ele.userId] =i
          });
        });
        console.log(couponsx,'coupon load');
        
        setcoupons(couponsx)
      }
    };

    const getPicksAll=async(checkIns:any)=>{
     const picksx:any = []
      checkIns.map(async(x:any,index:any)=>{
     const quer=  (await getDocs(query(collection(firebase,'vehiclePicks'),where('mobile','==',x.mobile),where('businessId','==',x.businessId),where('siteId','==',x.siteId))))
     substractRead(quer.size)
     quer.docs.map((row:any)=>{
      if(quer.size>0){        
        picksx.push(row.data())       
      }
      })
    })

    setpicks(picksx)
    }


    const colWidth = () => {
        const tabOption = [...data.tableOptions];
        const tableColumnsWidth: number[] = [];
    
        const colLng = 185;
        
        tabOption.sort((a:any,b:any)=>a.order-b.order).forEach((ele) => {
          
          if (ele.enabled) {
            if(ele.name=="mugshot"){
              tableColumnsWidth.push(120);
            }else if(ele.name=="time"){
              tableColumnsWidth.push(100);
            }else if(ele.name=="date"){
              tableColumnsWidth.push(100);
            }else{
            tableColumnsWidth.push(colLng);
            }
          }
        });
        // this.setState({  tableColumnsWidth });
        return tableColumnsWidth;
      };


      const getLoadingOptions = () => {
        const { loading } = data;
        const loadingOptions: TableLoadingOption[] = [];
    
        if (loading) {
          loadingOptions.push(TableLoadingOption.CELLS);
        }
    
        return loadingOptions;
      };

      const HandleColumnsReordered = (oldIndex: number, newIndex: number, length: number) => {
        setnumColumns(length);
        if (oldIndex === newIndex) {
          return;
        }
        const nextChildren = Utils.reorderArray(generateColumns(), oldIndex, newIndex, length);
        setcolumns(nextChildren);
      };

    const getCellData=<T extends keyof Users.CheckIn>(checkIns: Users.CheckIn[], rowIndex: number, type: T)=> {
        if (checkIns[rowIndex] && checkIns[rowIndex][type]) {
          return checkIns[rowIndex][type];
        }
        return null;
      }

    const  isMembershipExpired = (membership: any): boolean => {
        const expiry = new Date(membership.end_date);
        const today = new Date();
        if (new Date(expiry.toDateString()) < new Date(today.toDateString())) {
          return true;
        }
        return false;
      };
    
    const generateCellStyles = (membership?: any, covidquestions?: boolean) => {
        
        if (covidquestions) {
          return {
            alignItems: "center",
            background: "rgba(255, 2, 2, 0.1)",
            // borderBottom: "1px solid red",
            // borderTop: "1px solid red",
            color: "#ff0000",
            display: "flex",
            fontSize: data.fontSize,
            fontWeight: 900,
          };
        }
        return {
          alignItems: "center",
          background:
            membership &&
            membership.businessId != null &&
            data.businessId === membership.businessId &&
            data.businessId === "NGmCd9CIZx2C5GVDvrQH" &&
            !isMembershipExpired(membership)
              ? "rgba(15,153,96,.1)"
              : "",
          // borderBottom: membership ? "1px solid green" : "",
          // borderTop: membership ? "1px solid green" : "",
          color:
            membership &&
            membership.businessId != null &&
            data.businessId === membership.businessId &&
            !isMembershipExpired(membership)
              ? data.businessId === "NGmCd9CIZx2C5GVDvrQH"
                ? "#0f9960"
                : "#000"
              : "",
          display: "flex",
          fontSize: data.fontSize,
          fontWeight:
            membership &&
            membership.businessId != null &&
            data.businessId === membership.businessId &&
            !isMembershipExpired(membership)
              ? 900
              : 400,
        };
      };

// Colun renders//
    const  dateofbirthRenderer = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const dateofbirth:any = getCellData(checkIns, rowIndex, "dateofbirth");
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;

    
        if (dateofbirth && dateofbirth!==null && dateofbirth!=='') {
          try {
          
            const d = isTimestamp(dateofbirth) ? dateofbirth.toDate() : dateofbirth
          const prettyDoB = format(d, "M/d/yy");
    
          return (
            <Cell
              style={{
                ...generateCellStyles(membership, covidquestions),
                background:bgColor,
                border: "0.1px solid " + tableBorderColor,
                color: fontColor,
              }}
            >
              {prettyDoB}
            </Cell>
          );
          

      } catch (error) {
        console.log(error);
        
      }
    }
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions),
              background: tableBackgroundColor,
              border: "0.1px solid " + tableBorderColor,
              color:fontColor,
            }}
          />
        );
      };

    const getVehiclePics = (mob:any,bid:any,sid:any) => {
       

        const res = {pickLength:0, newInventory:0};
      let count = 0
 
        picks.map((row:any)=>{
          if(row.mobile===mob && row.businessId===bid && row.siteId===sid){                        
            count++
          }
        })
        res.pickLength=count;

        return res;
    
      }

    const obscureMobile = (value: string) => {
        const valueArr = value.split("");
        valueArr[4] = "*";
        valueArr[5] = "*";
        valueArr[6] = "*";
        return valueArr.join("");
      };
    const maskNumber = (num: any) => {
        const x = num.toString().match(/(\d{3})(\d{3})(\d{4})/);
        return "(" + x[1] + ")" + " *** " + x[3];
      };

    const prettyMobile = (mobile: string) => {
        const areaCode = mobile.substring(0, 3);
        const prefix = mobile.substring(3, 6);
        const suffix = mobile.substring(6, 10);
      
        return `(${areaCode}) ${prefix}-${suffix}`;
      };

    const mobileRenderer =(checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const { currentUserEmail } = data;
        let vehiclePickBlock = false
        // const { coupons } = this.state;
        let mobile = getCellData(checkIns, rowIndex, "mobile");
        const markForTheft = getCellData(checkIns, rowIndex, "markForTheft");
        const markForSellingCar = getCellData(checkIns, rowIndex, "markForSellingCar");
        const markUserNotes = getCellData(checkIns, rowIndex, "markUserNotes");
        const militaryFirstResopnder = getCellData(checkIns, rowIndex, "militaryFirstResopnder");
    
        const vehiclePickState = {status:false, new:false};
    
        // vihicle pick code start
    
        const vehiclePicks = getVehiclePics(checkIns[rowIndex].mobile,checkIns[rowIndex].businessId,checkIns[rowIndex].siteId);
    
            if(vehiclePicks.pickLength> 0){             
              
              vehiclePickBlock = true
              vehiclePickState.status = true;
              vehiclePickState.new = vehiclePicks.newInventory>0;
            }
      
            
    
    //    vihicle pick code end
    
    
        let couponIconBlock: number = 0;
        if (checkIns[rowIndex]) {
          console.log(coupons[checkIns[rowIndex].userId]);          
          couponIconBlock = coupons[checkIns[rowIndex].userId];
        }
    
        if (
          mobile &&
          (currentUserEmail === "cs1@picknpullsa.com" ||
            currentUserEmail === "cs2@picknpullsa.com" ||
            currentUserEmail === "garrett@upullits.com") &&
          mobile !== "6028181729"
        ) {
          // hide the full mobile number.
          mobile = obscureMobile(mobile);
        }
        let maskedMobile = "";
        if (mobile) {
          if (mobile !== "6028181729" && loginUser()!==null  ) {
            
            if (loginUser()!.accountData.permissions.ismask === true) {
              maskedMobile = maskNumber(mobile);
            } else {
              maskedMobile = prettyMobile(mobile);
            }
          }
        }
    
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;
    
        let iconMargin = "0px";
        let onlyMiletaryIcon = false;
        // if (mobile && (markUserNotes || markForTheft || markForSellingCar)) {
        if (mobile && (markUserNotes || markForTheft || markForSellingCar || (vehiclePickBlock && vehiclePickState.status))) {
          iconMargin = "19px";
          onlyMiletaryIcon = false;
        } else if (
          mobile &&
          (militaryFirstResopnder || couponIconBlock) &&
          !(markUserNotes || markForTheft || markForSellingCar)
        ) {
          iconMargin = "26px";
          onlyMiletaryIcon = true;
        }
    let user:any = {};
    
    data.users.map((v:any)=>{
try {
     
    if(v.userId===checkIns[rowIndex].userId){
      user =v;
    }
      
} catch (error) {
  console.log(error,v,checkIns[rowIndex]);
  
} 
    })
        
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions),
              // Some computers open a dialer when clicking on a mobile number
              background: bgColor,
              border: "0.1px solid " + tableBorderColor,
              color: fontColor,
              pointerEvents: "none",
            }}
          >
            <div style={{ marginTop: (finalCheckins[rowIndex] && finalCheckins[rowIndex].userId !== 'opt-out')?iconMargin:'0' }}>
              {mobile
                ? mobile === "6028181729"
                  ? "opted-out"
                  : maskedMobile
                : (finalCheckins[rowIndex] && finalCheckins[rowIndex].userId === 'opt-out')?  "opted-out" :
                  undefined}
            </div>
            <div style={{ display:(finalCheckins[rowIndex] && finalCheckins[rowIndex].userId !== 'opt-out')?'block':'none' }}>
            {/* <div> */}
          
    
              {mobile ? (
                couponIconBlock ? (
                  <span style={{ verticalAlign: onlyMiletaryIcon ? "sub" : "unset" }}>
                    <span className="table_coupon_icon">&nbsp;</span>
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}

{mobile && vehiclePickBlock && data.business.addaNewPickSwitch!==undefined && data.business.addaNewPickSwitch===true  ? (
                  vehiclePickState.status ? (
                    <span className="vehicle_picks_icon vehicle_pick_icon_btn">
                      <Icon intent={vehiclePickState.new?'success':'danger'} icon="drive-time"  />
                      &nbsp;&nbsp;
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
              )}

              {mobile ? (
                markUserNotes ? (
                  <span className={"table_notes_icon table_asterisk_icon"}>
                    <Icon intent="success" icon="asterisk" />
                    &nbsp;&nbsp;
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {mobile ? (
                markForTheft ? (
                  <span className="table_notes_icon">
                    <Icon intent="danger" icon="warning-sign" />
                    &nbsp;&nbsp;
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {mobile ? (
                markForSellingCar ? (
                  <span className="table_star_icon">
                    <Icon intent="warning" icon="star" />
                    &nbsp;&nbsp;
                  </span>
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {mobile ? (
                militaryFirstResopnder ? (
                  <span style={{ verticalAlign: onlyMiletaryIcon ? "middle" : "middle"}}>
                    <span className="table_american_icon1" style={{verticalAlign:'-webkit-baseline-middle'}}><img src={require('../../../assets/images/united-states.82bd821876ded6ae7d38.png')} width={'13%'}/></span>
                    &nbsp;&nbsp;
                  </span>
                ) : (
                  ""
                )                     
              ) : (
                ""
              )}
    
    {mobile ? (
              user!==null && user!==undefined && user.bannedCustomerOption!==undefined && user.bannedCustomerOption===true && 
              data.business!==undefined && data.business.bannedCustomerOption!==undefined && data.business.bannedCustomerOption===true
              ? (
                    <span className="table_notes_icon">
                      <Icon intent="danger" icon="disable" title="Banned Customer" />
                      &nbsp;&nbsp;
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
    
    {mobile ? (
              user!==null && user!==undefined && user.checkedIdCustomer!==undefined && user.checkedIdCustomer===true ? (
                    <span className="table_notes_iconx">
              <Icon intent="success" icon="endorsed" title="Checked Id"/>
                      &nbsp;&nbsp;
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
            </div>
          </Cell>
        );
      };
    
    const  nameRenderer = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const name = getCellData(checkIns, rowIndex, "name") as Users.User["name"];
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;
    
        if (name) {
          if (name.firstname && name.lastname && loginUser()!==null) {
            let lastName = "";
            if (loginUser()!.accountData.permissions.ismask === true) {
              lastName = "*******";
            } else {
              lastName = name.lastname;
            }
            return (
              <Cell
                style={{
                  ...generateCellStyles(membership, covidquestions),
                  background: bgColor,
                  border: "0.1px solid " + tableBorderColor,
                  color: fontColor,
                }}
              >{`${name.firstname} ${lastName}`}</Cell>
            );
          }
    
          if (name.displayName) {
            return (
              <Cell
                style={{
                  ...generateCellStyles(membership, covidquestions),
                  background: bgColor,
                  border: "0.1px solid " + tableBorderColor,
                  color: fontColor,
                }}
              >
                {name.displayName}
              </Cell>
            );
          }
        }
    
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions),
              background: bgColor,
              border: "0.1px solid " + tableBorderColor,
              color: fontColor,
            }}
          />
        );
    };

    const formatString = (path:any)=>{
        const strArr = path.split('-')
        strArr.forEach((ele:any, index:any) => {
          strArr[index] = ele.charAt(0).toUpperCase() + ele.slice(1);
        });
        return strArr.join(' ');
      }

    const siteRenderer = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const sitePath = getCellData(checkIns, rowIndex, "sitePath");
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;
    
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions),
              background: bgColor,
              border: "0.1px solid " + tableBorderColor,
              color: fontColor,
            }}
          >
            {/* {sitePath} */}
            {formatString(sitePath?sitePath:'')}
          </Cell>
        );
      };

    const  timestampRenderer = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const timestamp:any = getCellData(checkIns, rowIndex, "timestamp");
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;
        const d= isTimestamp(timestamp) ? timestamp.toDate() : timestamp
        const prettyTimestamp = timestamp
          ? format(d, "M/d/yy h:mma")
          : undefined;
    
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions),
              background: bgColor,
              border: "0.1px solid " + tableBorderColor,
              color: fontColor,
              textTransform: 'lowercase'
            }}
          >
            {prettyTimestamp}
          </Cell>
        );
      };

    const  dateRenderer = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const timestamp:any = getCellData(checkIns, rowIndex, "timestamp");
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;
        const d = isTimestamp(timestamp) ? timestamp.toDate() : timestamp
        const prettyTimestamp = timestamp
          ? format(d, "M/d/yy")
          : undefined;
    
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions),
              background: bgColor,
              border: "0.1px solid " + tableBorderColor,
              color: fontColor,
              textTransform: 'lowercase'
            }}
          >
            {prettyTimestamp}
          </Cell>
        );
      };


    const  checkInTypeRender = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const kind = getCellData(checkIns, rowIndex, "kind");
        const userId = getCellData(checkIns, rowIndex, "userId");
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;

        const checkIntype = userId==='opt-out' ? 'Opt-Out' : kind!==undefined && kind==='recheck' ? 'Recheck' : kind===undefined  ? 'New ' : kind===null ? 'Check-In (1st)' : 'Void';
    
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions),
              background:bgColor,
              border: "0.1px solid " + tableBorderColor,
              color: fontColor,
            }}
          >
            {checkIntype}
          </Cell>
        );
      };


    const  timeRenderer = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const timestamp:any = getCellData(checkIns, rowIndex, "timestamp");
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;
    
        const prettyTimestamp = timestamp
          ? format(isTimestamp(timestamp) ? timestamp.toDate() : timestamp, "h:mma")
          : undefined;
    
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions),
              background: bgColor,
              border: "0.1px solid " + tableBorderColor,
              color: fontColor,
              textTransform: 'lowercase'
            }}
          >
            {prettyTimestamp}
          </Cell>
        );
      };


    const  mugshotRenderer = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        let mugshot = getCellData(checkIns, rowIndex, "mugshot");
        if (!mugshot) {
          mugshot = getCellData(checkIns, rowIndex, "mugshotUrl");
        }
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;
        
        if (mugshot !== null) {
          let mugshotNew = false;
          if(checkIns[rowIndex] && checkIns[rowIndex].businessId === "JlxN1z6LzjJWjXcXlNnG"){
            switch(checkIns[rowIndex].name.firstname){
              case 'Brittany ':
                mugshotNew = true;
                break;
              case 'Michael':
                mugshotNew = true;
                break;
              case 'Rob':
                mugshotNew = true;
                break;
              case 'Shaquille':
                mugshotNew = true;
                break;
              case 'Taylor ':
                mugshotNew = true;
                break;
              case '"The Rock"':
                mugshotNew = true;
                break;
              case 'Tiger':
                mugshotNew = true;
                break;
              case 'Tom':
                mugshotNew = true;
                break;
            }
          }
        
          return (
            <Cell
              style={{
                ...generateCellStyles(membership, covidquestions),
                background:bgColor,
                border: "0.1px solid " + tableBorderColor,
                color: fontColor,
          
              }}
            >
              {mugshotNew?(
                checkIns[rowIndex].name.firstname === 'Brittany '?
                <img width="100" height="100" src={require("../../../assets/images/Brittany_Spears.jpg")} alt="pic" />:''            
              )
              :
              ''}
              {mugshotNew?(
                checkIns[rowIndex].name.firstname === 'Michael'?
                <img width="100" height="100" src={require("../../../assets/images/Michael_Jordan.jpg")} alt="pic" />:''            
              )
              :
              ''}
              {mugshotNew?(
                checkIns[rowIndex].name.firstname === 'Rob'?
                <img width="100" height="100" src={require("../../../assets/images/Rob_Gronkowski.jpg")} alt="pic" />:''            
              )
              :
              ''}
              {mugshotNew?(
                checkIns[rowIndex].name.firstname === 'Shaquille'?
                <img width="100" height="100" src={require("../../../assets/images/Shaq.jpg")} alt="pic" />:''            
              )
              :
              ''}
              {mugshotNew?(
                checkIns[rowIndex].name.firstname === 'Taylor '?
                <img width="100" height="100" src={require("../../../assets/images/Taylor_Swift.jpg")} alt="pic" />:''            
              )
              :
              ''}
              {mugshotNew?(
                checkIns[rowIndex].name.firstname === '"The Rock"'?
                <img width="100" height="100" src={require("../../../assets/images/The_Rock_Johnson.jpg")} alt="pic" />:''            
              )
              :
              ''}
              {mugshotNew?(
                checkIns[rowIndex].name.firstname === 'Tiger'?
                <img width="100" height="100" src={require("../../../assets/images/Tiger_Woods.jpg")} alt="pic" />:''            
              )
              :
              ''}
              {mugshotNew?(
                checkIns[rowIndex].name.firstname === 'Tom'?
                <img width="100" height="100" src={require("../../../assets/images/Tom_Brady.jpg")} alt="pic" />:''            
              )
              :
              ''}
              {!mugshotNew?
                <img width="100" height="100" src={mugshot} alt="pic" />            
              :
              ''}
              {/* <img width="100" height="100" src={mugshot} alt="pic" /> */}
            </Cell>
          );
        } else {
          return (
            <Cell
              style={{
                ...generateCellStyles(membership, covidquestions),
                background: bgColor,
                border: "0.1px solid " + tableBorderColor,
                color: fontColor,
              }}
            />
          );
        }
      };


    const  supercardRenderer = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const supercard = getCellData(checkIns, rowIndex, "supercard");
        const membership = getCellData(checkIns, rowIndex, "membership");
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions") === "yes" ? true : false;
    
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions),
              background:bgColor,
              border: "0.1px solid " + tableBorderColor,
              color: fontColor,
            }}
          >
            {supercard ? supercard.toString() : ""}
          </Cell>
        );
      };

    const covidquestionsRenderer = (checkIns: Users.CheckIn[], rowIndex: number,bgColor:string,fontColor:string) => {
        const covidquestions = getCellData(checkIns, rowIndex, "covidquestions");
        const membership = getCellData(checkIns, rowIndex, "membership");
        return (
          <Cell
            style={{
              ...generateCellStyles(membership, covidquestions === "yes" ? true : false),
              background: bgColor,
              border: "0.1px solid " + tableBorderColor,
              color: fontColor,
            }}
          >
            {covidquestions ? covidquestions.toUpperCase() : ""}
          </Cell>
        );
      };
    
    
    const assignRenderer = (checkIns: Users.CheckIn[], columnName: Users.ColumnName, rowIndex: number) => {

        let backgroundColor = '';
        let fontColor = '';
        let user:any;
        
        data.users.map((v:any)=>{
          try {    
          if(v.userId===checkIns[rowIndex].userId && checkIns[rowIndex].userId!=='opt-out'){
            user =v;
          }                  
        } catch (error) {
           console.log(error);            
        }
          });


        if(user!==null && user!==undefined && user.bannedCustomerOption!==undefined && user.bannedCustomerOption===true){
          try {
          if(data.business!==undefined && data.business.bannedCustomerOption!==undefined && data.business.bannedCustomerOption===true){
          backgroundColor=bannedCusotmerTableRowBgColor;
          fontColor=bannedCusotmerTableRowFontColor;
          }else{
            backgroundColor= tableBackgroundColor;
          fontColor=tableFontColor;
          }
          
            
        } catch (error) {
          backgroundColor= tableBackgroundColor;
          fontColor=tableFontColor;
            console.log(error);
            
        }
        }else{
          backgroundColor= tableBackgroundColor;
          fontColor=tableFontColor;
        } 
        if(checkIns[rowIndex]!==undefined && checkIns[rowIndex]!==null){
        switch (columnName) {
          case "dateofbirth":
            return dateofbirthRenderer(checkIns, rowIndex,backgroundColor,fontColor);
          case "mobile":
            return mobileRenderer(checkIns, rowIndex,backgroundColor,fontColor);
          case "name":
            return nameRenderer(checkIns, rowIndex,backgroundColor,fontColor);
          case "site":
            return siteRenderer(checkIns, rowIndex,backgroundColor,fontColor);
          case "timestamp":
            return timestampRenderer(checkIns, rowIndex,backgroundColor,fontColor);
            case "date":
              return dateRenderer(checkIns, rowIndex,backgroundColor,fontColor);
              case "kind":
                return checkInTypeRender(checkIns, rowIndex,backgroundColor,fontColor);
          case "time":
            return timeRenderer(checkIns, rowIndex,backgroundColor,fontColor);
          case "mugshot":
            return mugshotRenderer(checkIns, rowIndex,backgroundColor,fontColor);
          case "supercard":
            return supercardRenderer(checkIns, rowIndex,backgroundColor,fontColor);
          case "covidquestions":
            return covidquestionsRenderer(checkIns, rowIndex,backgroundColor,fontColor);
          default:
            return <Cell style={{ ...generateCellStyles() }} />;
        }
      }else{
        switch (columnName) {
          default:
            return <Cell style={{ ...generateCellStyles() }} />;
        }
      }
      };

    const capitalizeFirstLetter=(str:any)=> {
      
      if(str!==undefined){
      return str[0].toUpperCase() + str.slice(1);
      }else{
      return str
      }
    }
    const generateColumns = () =>  
      data.tableOptions
        .filter(({ enabled }:any) => enabled)
        .map(({ name,namex }:any, index:any) => (        
          <Column         
            cellRenderer={ (rowIndex) => assignRenderer(finalCheckins, name, rowIndex)}
            key={index}
            name={namex!==undefined ? capitalizeFirstLetter(namex) : capitalizeFirstLetter(name)}
            className={"table_base"}            
          />
        ));

    const transformSelection = (region: any): any => {
            const rowStart = region.rows && region.rows[0];
            console.log("in func");
            console.log(rowStart);
            if (rowStart || rowStart === 0) {
              return { cols: null, rows: [rowStart, rowStart] };
            } else {
              return region;
            }
        };


        
    
    return(
        <TableBase               
        columnWidths={colWidth()}        
        enableColumnResizing={false}
        enableFocusedCell={false}             
        enableRowHeader={false}              
        loadingOptions={getLoadingOptions()}
        numRows={numRows}        
        onColumnsReordered={HandleColumnsReordered}        
        onSelection={(e)=>{data.onSetActiveCheckIn(e)}}
        renderMode={RenderMode.NONE}        
        rowHeights={ range(0, numRows).map(() => fontSize * 7.5)}
        selectedRegionTransform={transformSelection}
        selectionModes={SelectionModes.ROWS_AND_CELLS}
        >
               {generateColumns()}
        </TableBase>
    )


}


export const isTimestamp = (value: Date | Timestamp): value is Timestamp =>
  typeof (value as Timestamp).toMillis === 'function';