import * as React from "react";
import CheckInsList from "./components/CheckInsList";
import SelectTimeFrame from "./components/SelectTimeFrame";
import PictureAndSignature from "./components/PictureAndSignature";
import LeftInfoBoxOfCheckIn from "./components/LeftInfoBoxOfCheckIn";
import TopLogos from "./components/TopLogos";
import { useAuthUser, withAuthUser } from "react-auth-kit";
import '../../assets/css/cashiergmview.css'
import store from "../../actions/store/store";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { firebase } from "../../global/firebase";
import { substractRead } from "../../global/documentreadcount";
class CashiersGmView extends React.Component<any,any> {
public state:any = {
    screenSize:window.innerWidth,
   
    dialogMore:false,
    coupons:[],
    userIdCurrent:'',
    load:false,
    todaysCouponOption:false,

    theme:{},
    loading:true,
    msg:'',
    listAboveText:'Showing Latest Check-ins',
    role:'',
    business:[],
    filterHours:[],
    site:[],
    busData:[],
    siData:[],
    selectedBusiness:'',
    selectedSite:'',
    currentData :[],
    picture:'https://www.texnrewards.com/wp-content/uploads/2023/05/mugshot.jpeg',
    sinature:'https://www.texnrewards.com/wp-content/uploads/2023/05/signature-2.png',
    viewCheckIn:{
        time:'',
        name:'',
        mobile:'',
        email:'',
        recheck:'',
        picture:'https://www.texnrewards.com/wp-content/uploads/2023/05/mugshot.jpeg',
        sinature:'https://www.texnrewards.com/wp-content/uploads/2023/05/signature-2.png',
        markForSellingCar:false,
        markForTheft:false,
        markUserNotes:false,
        militaryFirstResopnder:false,
        bannedCustomerOption:false,
        checkedIdCustomer:false,
        language:'',
        userId:'',
        
    },
    unsubscribe:{},
    pictureDisplay:'block',
    SignatureDisplay:window.innerWidth <750 ? 'none' :'block',
    popup:false,
}

public handleResize=()=> {
    console.log( window.innerWidth);
    
    this.setState({screenSize: window.innerWidth,SignatureDisplay:window.innerWidth <750 ? 'none' :'block'})
}
public componentDidMount(): void {
 
       window.addEventListener("resize", this.handleResize);
            const user = this.props.authState;           
            const {
              accountData: { permissions } ,} = user;
           this.setState({role:permissions.role,business:permissions.businesses,site:permissions.sites})
           setTimeout(() => {
            if(store.getState().BusinessesReducer.Businesses!==undefined && store.getState().BusinessesReducer.Businesses!==null){
           this.getbusinesses(permissions.role,permissions.businesses,permissions.sites)    
           this.filterhours();
            }
        }, 2000);
        
}



public filterhours=()=>{
const dateh = new Date().getHours();
const hour:any = [];
    for (let index = 1; index <= dateh; index++) {
        if(index>=8){
        hour.push(index);        
    }
}


this.setState({filterHours:hour});
}

public viewCheckIn =(row:any)=>{
    console.log(row,'kkkk');
    
    if(row!==undefined && row!==null && row.timestamp!==undefined && row.timestamp!==null){
    const checkin ={
    time:this.stampTotime(row.timestamp),
    name:row.name.displayName,
    mobile:!isNaN(parseInt(row.mobile,10)) ? row.mobile : 'Opt-Out',
    email:row.email,
    recheck:row.kind!==undefined && row.kind!==null ? 'Recheck' : '',
    picture:row.mugshot!==null ? row.mugshot : row.mugshotUrl,
    sinature:row.signature!==null ? row.signature : row.signatureUrl,
    markForSellingCar:row.markForSellingCar!==undefined ? row.markForSellingCar: false,
    markForTheft:row.markForTheft!==undefined ? row.markForTheft :false,
    markUserNotes:row.markUserNotes!==undefined ? row.markUserNotes :false,
    militaryFirstResopnder:row.militaryFirstResopnder!==undefined ? row.militaryFirstResopnder:false,
    bannedCustomerOption:row.bannedCustomerOption!==undefined ? row.bannedCustomerOption :false,
    checkedIdCustomer:row.checkedIdCustomer!==undefined ? row.checkedIdCustomer:false,
    language:row.language!==undefined ? row.language :'',
    userId:row.userId!==undefined ? row.userId:'',
}
this.setState({viewCheckIn:checkin});
    }

}

public clearRecoards=async()=>{
    this.setState({
        currentData :[],
        viewCheckIn:{
            time:'',
            name:'',
            mobile:'',
            email:'',
            recheck:'',
            picture:this.state.picture,
            sinature:this.state.sinature,
        }})
    return true;
}


public onSnapshotFun=async(bId:string,sId:string)=>{ 
    this.setState({loading:true}) 
    await this.clearRecoards();
    const date = new Date();
    const month = (date.getMonth()+1)<10 ? '0'+(date.getMonth()+1) :  date.getMonth()+1;
    const ddayy = date.getDate()<10 ? '0'+(date.getDate()) :  date.getDate();
    const fs17concat=  bId+'_'+date.getFullYear()+'_'+month+'_'+ddayy
console.log(month,'month');

    if(bId!=='' && sId!==''){

        const unsubscribe=  onSnapshot(doc(firebase, `fs19LastCheckInPointers/${bId}/fs20LastCheckInPointerByKiosk`, sId),{ includeMetadataChanges: false },async (snap:any) => {
                    this.setState({loading:true})    
                    if(snap.exists===false){
                    this.setState({loading:false})      
                    }                          
      if(snap.exists && snap.data()!==undefined &&  snap.data().document_id!==undefined ){        
        substractRead(1)
       const id:any =  snap.data();  
            if(id.type==='checkIn'){
                          
               const docRef =doc(firebase, `fs13CheckInsOrganizedByDate/${bId}/fs14CheckinsByBusiness/${sId}/fs15CheckinsByYear/${sId+'_'+date.getFullYear()}/fs16CheckinsByMonth/${sId+'_'+date.getFullYear()+'_'+month}/${'fs17_'+fs17concat}`, id.document_id)
                await getDoc(docRef).then(async(xx:any)=>{          
                                const prev:any = this.state.currentData;
                                substractRead(1)
                                const final:any = xx.data();                                
                                if(final!==undefined && final!==null){   

                                this.viewCheckIn(final)
                                prev.push(final)                           
                                prev.sort((a:any, b:any) => new Date(b.timestamp.toDate()).getTime() - new Date(a.timestamp.toDate()).getTime());
                                prev.length = 60     
                                                                                          
                                this.setState({currentData:prev,loading:false})  
                                } else{
                                    this.setState({loading:false});
                                }                                              
        }).catch((e:any)=>{
            this.setState({loading:false})   
        })

            }else{

              const qx = doc(firebase, `fs18Recheckins`,id.document_id);
               await getDoc(qx).then(async(xx:any)=>{        
                        const prev:any = this.state.currentData;
                        substractRead(1)
                        const final:any = xx.data();
                        if(final!==undefined && final!==null){ 
                        this.viewCheckIn(final)
                        prev.push(xx.data())
                        prev.sort((a:any, b:any) => new Date(b.timestamp.toDate()).getTime() - new Date(a.timestamp.toDate()).getTime());
                        prev.length = 60
                        this.setState({currentData:prev,loading:false})   
                        }else{
                            this.setState({loading:false});
                        } 
                        
        }).catch((e:any)=>{
            this.setState({loading:false})   
        })

            }
    }else{
        this.setState({loading:false});  
    }

    this.setState({unsubscribe});

    });

    }else{
        this.setState({loading:false})   
    }
         

}


public businessSelected=async(e:any)=>{
     
    try {
    const val = e.target.value;
    const sit:any = [];   
    let selectedsite=  ''; 
    await this.state.busData.map(async(t:any)=>{        
    if(t.sites!==undefined && t.sites!==null && t.businessId===val){
        selectedsite=t.sites[0].siteId;
        const couponsetting =t.todaysCouponOption!==undefined && t.todaysCouponOption!==null ?  t.todaysCouponOption : false; 
    this.setState({selectedSite:t.sites[0].siteId,theme:t.theme,todaysCouponOption:couponsetting})
    await t.sites.map((th:any)=>{
        sit.push(th)
      });
     }
    })
   this.setState({siData:sit,selectedBusiness:val})
   try {

   this.state.unsubscribe()
       
} catch (error) {
    console.log(error);
    
}
   this.onSnapshotFun(val,selectedsite);
} catch (error) {
   console.log(error);      
}
}

public SiteSelected=async(e:any)=>{
    try {
    const val = e.target.value;
    this.setState({selectedSite:val})
    this.state.unsubscribe()
   setTimeout(() => { this.onSnapshotFun(this.state.selectedBusiness,val);  }, 200); 
    } catch (error) {
    console.log(error);      
    }
}

public  hourtoAmPm=(hours:any) =>{

    const AmOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12;
  const hh  =hours<10 ? '0'+hours : hours;
   return  hh +' '+ AmOrPm;
  }

public  stampTotime=(date:any) =>{
    const toda = new Date(date.toDate()).toLocaleString();
  
    const today = new Date(toda);
    let hours = today.getHours() ; // gives the value in 24 hours format
    const AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
   const minutes = today.getMinutes()<10 ? '0'+today.getMinutes() : today.getMinutes() ;
  const hh  =hours<10 ? '0'+hours : hours;
   return  hh + ":" + minutes + " " + AmOrPm;
  }

public maskNumber = (mobile: string) => {
    const areaCode = mobile.substring(0, 3);
    const prefix = mobile.substring(3, 6);
    const suffix = mobile.substring(6, 10);
  
    return `(${areaCode}) ${prefix}-${suffix}`;
  };

public render(){
    const {busData,siData,theme,screenSize}=this.state;
console.log(screenSize);

    return (
        <div className="gm-main">
    
    {this.state.busData!==undefined && this.state.busData!==null?<div className="col-65 ">
    <TopLogos
       theme={theme}
       busData={busData}
       siData={siData}
       SiteSelected={(e:any)=>this.SiteSelected(e)}
       businessSelected={(e:any)=>this.businessSelected(e)}
    />


{<div>
       <LeftInfoBoxOfCheckIn
       data={this.state}
       maskNumber={(e:any)=>this.maskNumber(e)}
       />

       <PictureAndSignature
       data={this.state}
       pictureDisplay={this.state.pictureDisplay}
       SignatureDisplay={this.state.SignatureDisplay}
       setPicSignDisplay={(e:any)=>this.setState(e)}
       />
</div>}

         {screenSize>=1180 ?    <SelectTimeFrame 
            data={this.state}
            getPreviousData={(e:any)=>this.getPreviousData(e)}
            hourtoAmPm={(e:any)=>this.hourtoAmPm(e)}
            /> :''}
    </div>:''}
    {this.state.busData!==undefined && this.state.busData!==null ?<div className="col-35" style={{overflow:'auto'}}>
            <CheckInsList 
            data={this.state} 
            viewCheckIn={(e:any)=>this.viewCheckIn(e)}  
            maskNumber={(e:any)=>this.maskNumber(e)}  
            stampTotime={(e:any)=>this.stampTotime(e)} 
            searchRecoards={(e:any)=>this.searchRecoards(e)}
            getPreviousData={(e:any)=>this.getPreviousData(e)}
            setEtate={(e:any)=>this.setState(e)}
            hourtoAmPm={(e:any)=>this.hourtoAmPm(e)}
            />
 {screenSize>=750 && screenSize<=1180 ?    <SelectTimeFrame 
            data={this.state}
            getPreviousData={(e:any)=>this.getPreviousData(e)}
            hourtoAmPm={(e:any)=>this.hourtoAmPm(e)}
            /> :''}
            </div>:''}
        </div>
    )
}


public searchRecoards=(e:any)=>{
    const search = e.target.value;
    const data = this.state.currentData.map((v:any)=>{    
           try {
            if(v.mobile.indexOf(search)>=0 || search===''){
                v.show = true;
            }else if((v.name.displayName.toLowerCase()).indexOf(search.toLowerCase())>=0 || search===''){
                v.show = true;
            }else{
                v.show=false;
            }
            return v;
            
            
           } catch (error) {
            console.log(error);            
            return v;
           }
            });
            this.setState({currentData:data})
    }


    public getPreviousData=(h:any)=>{
        this.setState({msg:'',loading:true});
        let text:any = '';
        this.state.unsubscribe();
        if(h!=='live' && h!=='all'){
        const t1 = this.hourtoAmPm(h);
        const t2 = this.hourtoAmPm(h+1);
        text=    'Showing Check-ins Between '+t1+' and '+t2; 
        this.getDataBetweenTwotimes(h,1); 
        }else if(h==='all'){
            text=    'Showing Todays All Check-ins'; 
            this.getDataBetweenTwotimes(0,24); 
        }else{
        text=    'Showing Latest Check-ins';   
        setTimeout(() => { this.onSnapshotFun(this.state.selectedBusiness,this.state.selectedSite);  }, 300);  
        }      
        this.setState({listAboveText:text,currentData:[]});
    }

public getDataBetweenTwotimes=async(t1:any,add:any)=>{
    const today = new Date();
    const bId= this.state.selectedBusiness;
    const sId= this.state.selectedSite;
    let mm: any = today.getMonth()+1;
    let dd: any = today.getDate();
    const yyyy = today.getFullYear();
    if (mm < 10) {
        mm = "0" + mm;
    }

    if (dd < 10) {
        dd = "0" + dd;
    }
    today.setMinutes(0);
    today.setSeconds(0)
    const date =new Date(today.setHours(t1));
    const date2 = new Date(today.setHours(t1+add));    
    date2.setSeconds(-1)
    console.log(date,date2);
    const fs17concat=  bId+'_'+yyyy+'_'+mm+'_'+dd

console.log(`fs13CheckInsOrganizedByDate/${bId}/fs14CheckinsByBusiness/${sId}/fs15CheckinsByYear/${sId+'_'+yyyy}/fs16CheckinsByMonth/${sId+'_'+yyyy+'_'+mm}/${'fs17_'+fs17concat}`);

const q = query(collection(firebase, `fs13CheckInsOrganizedByDate/${bId}/fs14CheckinsByBusiness/${sId}/fs15CheckinsByYear/${sId+'_'+yyyy}/fs16CheckinsByMonth/${sId+'_'+yyyy+'_'+mm}/${'fs17_'+fs17concat}`),where('timestamp','>=',date),where('timestamp','<=',date2));
   await getDocs(q).then(async(f:any)=>{  
        if(!f.empty){
            substractRead(f.size)
            const prev:any = [];
            f.forEach((ed:any)=>{
            const final:any = ed.data();                             
            prev.push(final) 
            })                          
            prev.sort((a:any, b:any) => new Date(b.timestamp.toDate()).getTime() - new Date(a.timestamp.toDate()).getTime());
            this.viewCheckIn(prev[prev.length-1]);
            prev.length = 100                                
            this.setState({msg:'',currentData:prev,loading:false,popup:false})  
        }else{
            this.setState({msg:'No Data Found',loading:false,popup:false});            
        }

    }).catch((e:any)=>{
        this.setState({loading:false});
    });
 }




 public getbusinesses=async(role:any,business:any,Allsite:any)=>{

try {

  
    const busienss =  await store.getState().BusinessesReducer.Businesses;   
    if(role==='admin'){  
      this.setState({busData:busienss});
      const sit:any = [];
      const tb = busienss[0]
     console.log(tb,tb.sites[0].siteId,tb.businessId);     
      if(tb!==undefined && tb.sites!==undefined){
       await tb.sites.map(async(th:any)=>{
           await sit.push(th)
        });  
    } 

    const couponsetting =tb.todaysCouponOption!==undefined && tb.todaysCouponOption!==null ?  tb.todaysCouponOption : false;
    this.setState({selectedSite:tb.sites[0].siteId,selectedBusiness:tb.businessId,siData:sit,theme:tb.theme,todaysCouponOption:couponsetting})

    this.onSnapshotFun(tb.businessId,tb.sites[0].siteId);
      
    }else{
        const yard:any = [];
      await busienss.map(async(t:any)=>{
            if(t.sites!==undefined && t.sites!==null && business!==undefined && business.includes(t.businessId)){
               const kiosk:any=  []
           await t.sites.map(async(th:any)=>{                        
                    if(Allsite!==undefined && Allsite.includes(th.siteId)){
                   await kiosk.push(th) 
                    }
              });              
                           
          if(kiosk[0]===undefined){
           await t.sites.map(async(thh:any)=>{ 
                  await kiosk.push(thh)
                }); 
              }            
            t.sites = kiosk;
           await yard.push(t)
           
           this.setState({busData:yard});
           const sitxn:any = [];
           const txn = yard[0];                      
           await txn.sites.map(async(thx:any)=>{
             await sitxn.push(thx)
               });   

           const couponsetting =txn.todaysCouponOption!==undefined && txn.todaysCouponOption!==null ?  txn.todaysCouponOption : false; 
           this.setState({selectedSite:txn.sites[0].siteId,selectedBusiness:txn.businessId,theme:txn.theme,siData:sitxn,todaysCouponOption:couponsetting})

           this.onSnapshotFun(txn.businessId,txn.sites[0].siteId);
             }      
            }) 

    }

} catch (error) {
    console.log(error);
   
}
}


}

export default withAuthUser(CashiersGmView);