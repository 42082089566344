
import { Button, ButtonGroup, Classes, HTMLTable, Icon, Label, Spinner } from '@blueprintjs/core';
import { Cell, Column, ColumnHeaderCell,  SelectionModes,  Table2 } from '@blueprintjs/table';
import { deleteDoc, doc, getDocs, orderBy, query, where } from 'firebase/firestore';
import React , { useEffect, useState }  from 'react';
import { UsersCollection } from '../global/firebase';
import { useSelector } from 'react-redux';
import { EditUserPopup } from '../components/EditUserPopup';
import { substractRead } from '../global/documentreadcount';

export const UsersList=()=>{
    const [Users,setUsers]=useState([])
    const [rowChecked,setRowChecked]=useState([])
    const [EditUsers,setEditUsers]=useState([])
    const [IsActiveEditUsers,setIsActiveEditUsers]=useState(false)
    const [Businesses,setBusinesses]=useState([])
    const [docsSize,setdocsSize]=useState(0)
    const store = useSelector((state:any) => state)
    useEffect(()=>{          
        setBusinesses(store.BusinessesReducer.Businesses)
                            
    },[store])

    const emailRender = (rowIndex: number,Users:any) => {
        const user = Users[rowIndex]    
        return(user.accountData.email)
       };
    const BusinessesRender =(rowIndex: number,Users:any) => {
        const user = Users[rowIndex]           
        let name:any[] =[];
        Businesses.forEach((r:any)=>{
             if(user.accountData.permissions.businesses.includes(r.businessId)){                              
                name.push(r.businessName);
             } 
        })       
        return(user.accountData.permissions.role!=='admin' ? name.map((v:any,index:any)=>(<span  key={index}><Button  small={true} style={{margin:'2px'}} intent='success'>&nbsp;{v}&nbsp;</Button>&nbsp;&nbsp;</span>)) :'All')         
       };
    const RoleRender = (rowIndex: number,Users:any) => {
        const user = Users[rowIndex]           
        return(user.accountData.permissions.role)
       };
     
    const ActionRender = (rowIndex: number,Users:any) => {
        const user = Users[rowIndex]           
        return(
            <Button  intent='primary' icon='edit' onClick={()=>OpenEditBox(user)}/>)
       };  

    const SelectRender= (rowIndex: number,Users:any)=>{
      console.log(rowIndex,'okpo');
      
       return(<Label><input type='checkbox'  onChange={(e:any)=>selectRow(e,Users[rowIndex])} style={{    height:'25px',width: '25px'}}/></Label>)
    }   
   
    function renderColumnHeader(index: number) {
        const header = ["Select","Email","Businesses","Role","Action"][index]!;
        return <ColumnHeaderCell  name={header} index={index} nameRenderer={renderName} />;
    }

 const selectRow=(e:any,user:any)=>{
    const checked = e.target.checked;
    const userId=  user.userId;
    if(checked){
    const row = rowChecked.concat(userId)
     setRowChecked(row)
    }else{
     const r = rowChecked.filter((r)=>(r!==userId))
     setRowChecked(r)

    }
 }

    useEffect(()=>{
       getusers()
    },[]);

        const getusers=async()=>{
            const q = query(UsersCollection, where("hasAccount", "==", true),orderBy('accountData.permissions.role', 'asc'));
            const querySnapshot = await getDocs(q);
            substractRead(querySnapshot.size)
            const userArray:any = []   
            setdocsSize(querySnapshot.size)         
            querySnapshot.forEach((r)=>{
                
            const u = r.data();
            userArray.push(u)
            })
             setUsers(userArray)

      
        }

        const OpenEditBox=(user:any)=>{
         setEditUsers(user)     
         setIsActiveEditUsers(true)       
        }
        const CloseEditUserPopup=()=>{
            setIsActiveEditUsers(false);
            setEditUsers([]);
        }
const deleteUsers=()=>{
if(window.confirm('Are you sure to delete selected users.')){
    if(rowChecked.length>0){
        rowChecked.map(async(id:any)=>{
            await deleteDoc(doc(UsersCollection, id)).catch((e)=>{
                                
            }).then(()=>{
                const userss =  Users.filter((v:any)=>v.userId!==id);
                const users5:any =  Users.filter((v:any)=>v.userId===id);                
               setUsers(userss);
               try {
                alert(users5[0].accountData.email+' Successfully Deleted.')

               } catch (error) {
                console.log(error);
                
               }
            });
        });
    }

    
}
}
        return (
            <div className='UserList'><br/>
      {Businesses[0]!==undefined ?  
      <div>
        <ButtonGroup alignText='left'><Button disabled={rowChecked.length<=0} intent='danger' onClick={deleteUsers}><Icon icon='trash' />&nbsp;&nbsp;&nbsp;Delete</Button></ButtonGroup>
    

        <HTMLTable striped={true} >
          <thead>
            <tr>
              
              <th>Select</th>
              <th>email</th>
              <th>business</th>
              <th>role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Users.map((user, index) => (
                <tr>
                   <td>{SelectRender(index,Users)}</td>
                   <td>{emailRender(index,Users)}</td>
                   <td>{BusinessesRender(index,Users)}</td>
                   <td>{RoleRender(index,Users)}</td>
                   <td>{ActionRender(index,Users)}</td>
                </tr>
            ))}
          </tbody>
        </HTMLTable>

        </div>:
         <Spinner         
         intent={'primary'}
         size={70} />
        }
        {IsActiveEditUsers ? <EditUserPopup Onclose={()=>CloseEditUserPopup} user={EditUsers}/>:''}
            </div>
        )



function renderName(name: string) {
    return (
        <div style={{ lineHeight: "24px" }}>
            <div className={Classes.TEXT_LARGE}>
                <strong>{name}</strong>
            </div>
        </div>
    );
}
}