
import { Button, H1, H4, HTMLTable, Icon } from "@blueprintjs/core";
import * as React from "react";
import { urls } from "../../global/firebase";
import '../../assets/css/style.css'
import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";
import { range } from "lodash";
export  class LocationCouponData extends React.Component{

public state:any={
    coupons:[],
    yardapikey:'',
    couponsfinal:[],
    valu:[]
}

public  componentDidMount(): void {
    const params = new URL(window.location.href).searchParams;  
    const yardapikey = params.get("yard_api_key")!; // is the string "Jonathan Smith".
    const val = params.get("val")!; // is the string "Jonathan Smith".
    this.setState({valu:val.split(',')})
    this.setState({yardapikey})
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("yard_api_key", yardapikey);
    
    const requestOptions:any = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
    fetch(urls.pointsystem+"p1s5e1_get_location_coupon_data", requestOptions)
      .then(response => response.json())
      .then(result => {
         this.setState({coupons:result,couponsfinal:result})   
         console.log(result);
          
        })
      .catch(error => console.log('error', error));
}


public render(){
    const styleTh=  {
        padding: '4px',
        border: '1px solid #000',
        textAlign:'center'as const

    }

    const styleThx=  {
        padding: '4px',
        border: '1px solid #000',
        textAlign:'left'as const
    }
    const styleThy=  {
        padding: '4px',
        border: '1px solid #000',
        textAlign:'right'as const
    }
    const stylespan=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'22px',
        cursor:'pointer',
        color:'#fff'

    }
    const stylespan2=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'15px',
        cursor:'pointer'
    }

const Search=(v:any)=>{
    const arre:any=[]; 

    
 Object.keys(this.state.couponsfinal).sort().filter((x:any)=>{
if(x.includes(v)){
    arre[x] =this.state.couponsfinal[x]
 return true
}else{
    return false
}

    })
    this.setState({coupons:arre})
}
const Datetimeformate=(date:any)=>{
    
    const da = new Date(date);
    const day = da.getUTCDate()<10 ? '0'+da.getUTCDate():da.getUTCDate()
    const month = (da.getUTCMonth()+1)<10 ? '0'+(da.getUTCMonth()+1):(da.getUTCMonth()+1)
    const year = da.getUTCFullYear()
    
    return `${month}/${day}/${year}`
    }


    const AmountFormate=(x:any)=>{
           
        const options = { 
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          };
            const formatted =  Math.round(Number(x)).toLocaleString('en', options);
            console.log(formatted);
        return formatted
    }

    const prettyMobile = (mobile: string) => {
        const areaCode = mobile.substring(0, 3);
        const prefix = mobile.substring(3, 6);
        const suffix = mobile.substring(6, 10);
      
        return `(${areaCode}) ${prefix}-${suffix}`;
      };

const csvDownload=()=>{
    try {
 
    const coupons = this.state.coupons;
    console.log(coupons);
    const final:any =[]

    Object.keys(this.state.coupons).sort().map((x:any)=>{
        const arr:any = []
        arr.push(['Customer:  '+prettyMobile(x),' ',' ',' ',' ',' '])
        arr.push(['Coupon Deduction','Coupon Issue Date','Coupon Expire Date',' ',' ',' '])
        if(this.state.coupons[x].coupons!==undefined && this.state.coupons[x].coupons.length>0){        
            this.state.coupons[x].coupons.map((r:any)=>{

                arr.push([valuupdate(r.coupon_percentage+'%'),Datetimeformate(r.issue_date),Datetimeformate(r.expiry_date),' ',' ',' ']);                       
            })
        }else{
            arr.push(['','None Exists',' ',' ',' ',' ']); 
        }
       
        
        if(this.state.coupons[x]!==undefined  && this.state.coupons[x].next_coupon!==undefined){

        console.log(arr.length);
if(arr.length<=11){
    range(11-arr.length).map(()=>{
        arr.push([' ',' ',' ',' ',' ','']);
    })
}
            arr[1][4] = 'Next Reward Milestone'
            arr[2][4] = 'Coupon Percentage'
            arr[3][4] = 'Points Earned'
            arr[4][4] = 'Points Needed'
            arr[5][4] = 'Points Needed'
            arr[5][4] = ''
            arr[6][4] = 'Customer Spend Summary'
            arr[7][4] = 'Last Month Spend'
            arr[8][4] = 'Last 3 Months Spend'
            arr[9][4] = 'Last 6 Months Spend'
            arr[10][4] = 'Last Year Spend'



            arr[2][5] = valuupdate(this.state.coupons[x].next_coupon.coupon_percentage+'%')
            arr[3][5] = Math.round(parseInt(this.state.coupons[x].next_coupon.progress_towards,10))+' Points'
            arr[4][5] = Math.round(parseInt(this.state.coupons[x].next_coupon.progress_left,10))+' Points'

   if(this.state.coupons[x]!==undefined  && this.state.coupons[x].spent_amounts!==undefined ){
    arr[7][5] = '$'+Math.round(this.state.coupons[x].spent_amounts.last_month.toFixed(2))
    arr[8][5] ='$'+Math.round(this.state.coupons[x].spent_amounts.last_3_months.toFixed(2))
    arr[9][5] = '$'+Math.round(this.state.coupons[x].spent_amounts.last_6_months.toFixed(2))
    arr[10][5] = '$'+Math.round(this.state.coupons[x].spent_amounts.last_year.toFixed(2))
   }

            
   }
   arr.push(['','',' ',' ',' ',' ']); 
   final.push(arr)
   })



var data = final;
const finalarr:any = [];
data.map((x:any)=>{
    x.map((nx:any)=>{
        finalarr.push(nx)
    })

})


const seperator = ",";


const csv = [
    ...finalarr.map((row:any )=>  [0,1,2,3,4,5].map(field => `${row[field]}`).join(seperator))
]

//Export our csv in rows to a csv file
let csvContent = "data:text/csv;charset=utf-8," 
    + csv.join("\n");
var encodedUri = encodeURI(csvContent);
window.open(encodedUri);
       
} catch (error) {
        console.log(error);
        
}
}

const valuupdate=(ind:any)=>{    
    let wr = '';
    if(this.state.valu.length>0){
        this.state.valu.map((v:any)=>{
if(v.includes(ind)){
    wr=v
}

        })
    }

    return wr;
}
    return(
        <div style={{padding:'13px',fontSize:'17px',textAlign:'left',background:'#fff'}}>
            {/* <H1>Yard Api Key: {this.state.yardapikey}</H1> */}
          <div style={{position:'fixed',padding:'5px',border:'1px solid #000',top:'0px',background:'#fff'}}>
            
             &nbsp;&nbsp;Search: <input type="search" placeholder="search by number" onChange={(e)=>Search(e.target.value)}/>
             &nbsp;&nbsp; <Button intent="success" onClick={()=>csvDownload()} title="download csv"><Icon icon='document'/></Button>

             </div> 
            {
           Object.keys(this.state.coupons).sort().map((x:any)=>(
                  x!=='errors'?          
                  <div style={{margin:'10px',background:'slategray',padding:'5px',width:'90vw'}}>
                    <H4><span style={stylespan}>{prettyMobile(x)}</span></H4>
              
                <div className="row-full-width">
                    <div className="w-71">
                    <div  style={{margin:'10px',background: 'antiquewhite',padding:'4px'}}>
                        <H4><span style={stylespan2}>{'Active Coupons'}</span></H4>
                        <p  style={{marginLeft:'20px',marginTop:'8px',overflow:'auto'}}>                       
                                <tr>
                                    <th style={styleTh}>Coupon Deduction</th>
                                    <th style={styleTh}>Coupon Issue Date</th>
                                    <th style={styleTh}>Coupon Expire Date</th>
                                </tr>
                            {this.state.coupons[x].coupons!==undefined && this.state.coupons[x].coupons.length>0 ? this.state.coupons[x].coupons.map((r:any)=>(
                                <tr>
                               
                                    <td style={styleTh}>{valuupdate(r.coupon_percentage+'%')}</td>
                                    <td style={styleTh}>{Datetimeformate(r.issue_date)}</td>
                                    <td style={styleTh}>{Datetimeformate(r.expiry_date)}</td>
                                </tr>
                            )):<tr><td colSpan={3}>No active coupons at this time</td></tr> }
                       
                        </p>
                        </div>
                

                     </div>
                     <div className="w-31">
                        <div  style={{margin:'10px',background:'ghostwhite',padding:'4px'}}>
                        <H4><span style={stylespan2}>{'Next Reward Milestone'}</span></H4>
                        {this.state.coupons[x]!==undefined  && this.state.coupons[x].next_coupon!==undefined  ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                             <HTMLTable>
                                <tr>
                                <th style={styleThx}>Coupon Percentage</th>
                                <td style={styleThy}>{valuupdate(this.state.coupons[x].next_coupon.coupon_percentage+'%')}</td>
                                </tr>                               
                                <tr>
                                <th style={styleThx}>Points Earned</th>
                                <td style={styleThy}><b>{AmountFormate(parseInt(this.state.coupons[x].next_coupon.progress_towards,10))} Points</b></td>
                                </tr>
                                <tr>
                                <th style={styleThx}>Points Needed</th>
                                <td style={styleThy}><b>{AmountFormate(parseInt(this.state.coupons[x].next_coupon.progress_left,10))} Points</b></td>
                                </tr>
                                </HTMLTable>
                        </p>
                        :'No Next coupon at this time'}
                        </div>
                        <div style={{margin:'10px',background:'skyblue',padding:'4px'}}>
                        <H4><span style={stylespan2}>{'Customer Spend Summary'}</span></H4>
                        {this.state.coupons[x]!==undefined  && this.state.coupons[x].spent_amounts!==undefined  ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                                   <HTMLTable>
                                <tr>
                                <th style={styleThx}>Last Month Spend	</th>
                                <td style={styleThy}>${AmountFormate(this.state.coupons[x].spent_amounts.last_month.toFixed(2))}</td>
                                </tr>
                                <tr>
                                <th style={styleThx}>Last 3 Months Spend	</th>
                                <td style={styleThy}>${AmountFormate(this.state.coupons[x].spent_amounts.last_3_months.toFixed(2))}</td>
                                </tr>
                                <tr>
                                <th style={styleThx}>Last 6 Months Spend	</th>
                                <td style={styleThy}>${AmountFormate(this.state.coupons[x].spent_amounts.last_6_months.toFixed(2))}</td>
                                </tr>
                               
                                <tr>
                                <th style={styleThx}>Last Year Spend	</th>
                                <td style={styleThy}>${AmountFormate(this.state.coupons[x].spent_amounts.last_year.toFixed(2))}</td>
                                </tr>
                                </HTMLTable>
                        </p>
                        :''}
                    </div>
                    </div>
                </div>
                    </div>
           :'' ))
          }
        </div>
    )
}


}


