import * as React from "react";
import '../../assets/css/points.css'
import { firebase as firestore } from "../../global/firebase";
import Form from "./Form";
import BusinessList from "./BusinessList";
import { urls } from "../../global/firebase";
import { collection, getDocs, query } from "firebase/firestore";
import { substractRead } from "../../global/documentreadcount";

class Points extends React.Component<any,any> {
public state:any = {
  PointBusinessIds:[],
  businessname:'',
    business:[],
    Tiers:'',
    earnetdelay:'',
    selectedBusinessId:'',
    pointsvalue:'',
    expirydays:'',
    tierDivs: [
        {
            'pointVTRR':'',
            'rV':'',
            'type':'$',
            'cca':''
        }
    ],
    Qualifying:[
        {
            'nOT':'',
            'mas':'',
            'rV':'',
            'type':'$',
            'ccp':'',

        }
    ],
    msg:'',
    days:365,
    isDisabled:0,
    loading:false,    
    success:'',
    apiKey:'',
    order:0,
    generateKey:'',
}


public GetBusiness=async()=>{  
  const xhr = new XMLHttpRequest();
  xhr.open('POST', urls.pointsystem+'p1s5e6_get_businesses', true);
  xhr.setRequestHeader('Content-type', 'application/json');
  xhr.onload = async (x:any) =>{
      // do something to response
  const business = await JSON.parse(x.target.response)
  console.log(business);
  
  const arr:any=  [];
  Object.keys(business).forEach(async(k,i) => {      
    if(business[k]!==undefined && business[k].name!==undefined){

    business[k].order = business[k].is_disabled!==undefined && business[k].is_disabled===1 ? 3 : 1;
    arr.push(business[k])
    }
  })  

  this.GetBusinessFireStore(arr);

  }
  xhr.send();
}

public GetBusinessFireStore=async(arr:any)=>{ 
  
  const BIds:any=[];
  arr.map((e:any)=>{
    BIds.push(e.texn_business_uid);
  })
  this.setState({PointBusinessIds:BIds});
  const data:any = arr;  
  let i=arr.length>0 ?arr[arr.length-1].business_uid:'';

  await getDocs(query(collection(firestore,'businesses'))).then(async(v:any)=>{
    substractRead(v.size)
  await v.docs.map(async(row:any)=>{
    const BusinessRow =row.data();   
    if(!BIds.includes(row.id)){
      const S3Ids:any = [];

      await getDocs(query(collection(firestore,`businesses/${BusinessRow.businessId}/sites`))).then(async(sites3:any)=>{ 
        
        if(BusinessRow.s3Id!==undefined && BusinessRow.s3Id!==null && BusinessRow.s3Id!==''){
          S3Ids.push(BusinessRow.s3Id);
        }
      substractRead(sites3.size)
      await  sites3.docs.map(async(y:any)=>{        
        const r= y.data();       
          if(r.s3Id!==undefined && r.s3Id!==null && r.s3Id!==''){
            S3Ids.push(r.s3Id);
          }
        })
      })
    if(S3Ids.length>0){
      i +=1;
        const rowx = {
          order :2,
          business_uid:i,
          texn_business_uid:BusinessRow.businessId,
          name:BusinessRow.path,
          coupon_rule_method:2,
          s3_ids:S3Ids,
          coupon_rule_settings:{
            CET2:{
              coupon_cap_amount:0,
              coupon_expiry_days:365,
              coupon_type:2,
              coupon_value:0,
              minimum_purchase:0,
              number_of_transactions:0,

            }
          }
        }       
      data.push(rowx);
    }
  }  
    })
    console.log(data);
   setTimeout(async() => {
  await data.sort((a:any, b:any) =>a.order - b.order);
    this.businessChange(data[0].business_uid);
  this.setState({business:data,loading:false,})
}, 1000); 
  });

}

public componentDidMount(){  
 this.GetBusiness();
 this.setState({loading:true});
}

public removediv=(x:any)=>{
  const p =  this.state.tierDivs.filter((v:any)=>{
return v!==x;
    })
    this.setState({tierDivs:p})
}
      
public addnew=()=>{
    const p =  this.state.tierDivs;
    p.push({'pointVTRR':'','rV':'','type':'$','cca':''})
    this.setState({tierDivs:p})
}

public Updatevalues=(x:any,i:any,V:any,value:any)=>{
  this.state.tierDivs[i][V]=value;
  this.setState({tierDivs:this.state.tierDivs})
  }


  public Updatevaluesquantity=(i:any,V:any,value:any)=>{
    this.state.Qualifying[i][V]=value;
    this.setState({Qualifying:this.state.Qualifying})

    }


    public getApiKey=(id:any)=>{
      try {
    this.setState({loading:true});
      const data = new FormData();
      const $this =this;
      data.append('business_uid',id.toString());
      const xhr = new XMLHttpRequest();
      xhr.addEventListener("readystatechange",function(){       
        if (this.readyState === 4) {
          const key =JSON.parse(this.responseText)[id];
          console.log(key);
          
         $this.setState({apiKey:key,loading:false});
        }
      });
      xhr.open("POST", urls.pointsystem+"p1s5e8_get_yards_api_key");
      xhr.setRequestHeader("cache-control", "no-cache");
      xhr.send(data);
          
    } catch (error) {
      console.log(error);
    }
    }

    


    public updateKey=()=>{
      const BId  =this.state.selectedBusinessId;
      // const apiKey=  this.state.apiKey;
      if(window.confirm('Changing the API Key will make all requests from POS terminal invalid until the new API key is not updated to that terminal. Are sure you still want to update the API Key')){
      if(BId!=='' ){
      if(this.state.generateKey!=='' && this.state.generateKey.trim()!=='' ){

        try {
         const generateKey =this.state.generateKey;
          const data = new FormData();
          const $this = this;
          data.append('business_uid',BId.toString());
          data.append('yard_api_key',generateKey);
          const xhr = new XMLHttpRequest();
          xhr.addEventListener("readystatechange",function(){       
            if (this.readyState === 4) {
             console.log(this.responseText);
             alert('Key Updated Successfully')             
             $this.setState({generateKey});
             $this.clearCET1CET2(1);
             
            }
          });
          xhr.open("POST", urls.pointsystem+"p1s5e9_update_yard_api_key");
          xhr.setRequestHeader("cache-control", "no-cache");
          xhr.send(data);
              
        } catch (error) {
          console.log(error);
        }
      
      }else{
        alert('Api key field is required for update key')
      }

      }else{
        alert('Please Select Business')
      }
    }else{
      console.log('deny');
      
    }
      
      }

public businessChange=async(value:any)=>{
this.getApiKey(value);
await this.clearCET1CET2(0);
setTimeout(() => {
this.setState({selectedBusinessId:value,generateKey:''})
const business = this.state.business;
    business.map((t:any)=>{
if(t.business_uid===value ){
this.setState({businessname:t.name,order:t.order})
    if(t.coupon_rule_settings.CET1!==undefined){
      const cet1 = t.coupon_rule_settings.CET1;
    //  CET1     
    const tierDivs:any= []
    t.coupon_rule_settings.CET1.coupon_brackets.map((v:any)=>{
      
tierDivs.push({
  'pointVTRR':v[0],
  'rV':v[2],
  'type':v[1]===2 ? '$' : '%',
  'cca':v[3],
})
    });

 this.setState({
  isDisabled:t.is_disabled,
  tierDivs,
  pointsvalue:cet1.ratio_multiplier,
  earnetdelay:cet1.point_earned_time_delay,
  days:cet1.coupon_expiry_days!==null ? cet1.coupon_expiry_days : '',
  Tiers:'Tiered'
 });
  
    }else if(t.coupon_rule_settings.CET2!==undefined){
    //  CET2
 const cet2 = t.coupon_rule_settings.CET2;
 console.log(cet2);
 
    this.setState({
      isDisabled:t.is_disabled,
      Qualifying:[{
        'nOT':cet2.number_of_transactions,
        'mas':cet2.minimum_purchase,
        'rV':cet2.coupon_value,
        'type':cet2.coupon_type===2 ? '$' : '%',
        'ccp':cet2.coupon_cap_amount,
              }],
      Tiers:'Qualifying',
      expirydays:cet2.coupon_expiry_days,
     });
      

    }else{
      alert('!Something goes wrong. contact with developer')
    }
 
}
    })
      
}, 1000);
}



  public  render(){

        return (
        <div className="container">
          <div className="col-3">
              <BusinessList
              businesses={this.state}
              businessChange={(e:any)=>this.businessChange(e)}
              />
          </div>
          <div className="col-9">
          <Form
                    data={this.state}                  
                    Updatevalues={(x:any,i:any,t:any,y:any)=>this.Updatevalues(x,i,t,y)}
                    updateKey={()=>this.updateKey()}
                    removediv={(e:any)=>this.removediv(e)}
                    addnew={()=>this.addnew()}
                    Updatevaluesquantity={(int:any,m:any,v:any)=>this.Updatevaluesquantity(int,m,v)}
                    handleform={this.handleform}
                    setState={(e:any)=>this.setState(e)}
                    clearCET1CET2={(e:any)=>this.clearCET1CET2(e)}
                  />
          </div>
        </div>
       
        )
    }

               
    public handleform=()=>{
          if(this.state.Tiers==='Tiered'){
            const divs = this.state.tierDivs;            
            let count = 0;
          divs.map((n:any)=>{
            if(n.pointVTRR.toString().trim()==='' || n.rV.toString().trim()==='' || n.type.toString().trim()==='' || this.state.pointsvalue.toString().trim()==='' ){
          count ++;
            }else{
          count= 0;
            }
          });

        if(count>0){
            this.setState({msg:'Please fill all above required fields*'})
          }else{
          
            this.submitFormFinal('CET1',this.state)
          }  
    }else{

  const vor = this.state.Qualifying[0];
  if(vor.mas.toString().trim()==='' || vor.nOT.toString().trim()==='' || vor.rV.toString().trim()==='' || vor.type.toString().trim()==='' ){
  this.setState({msg:'Please fill all  above required fields*'})
  }else{
    this.submitFormFinal('CET2',this.state)
  }
}
    }


    public submitFormFinal=async(type:any,value:any)=>{
      this.setState({loading:true});
      const Id = this.state.selectedBusinessId;
     const business = this.state.business;
     let index:any;
    const newBusienss =   business.map((t:any,i:any)=>{
       if(t.business_uid===Id){
         index = i;
         t.business_name=t.name;
         t.s3_ids.filter((v:any)=>{
         return parseInt(v,10);
         })
          delete(t.name);
         if(type==='CET1'){  
                    
                    t.coupon_rule_method=1; 
                    t.is_disabled= this.state.isDisabled;
                    t.coupon_rule_settings={
                      CET1:{}
                    }       
              const cet1 = t.coupon_rule_settings.CET1;    
              const mapvalue:any = [];
              value.tierDivs.map((final:any)=>{
                    const typ = final.type==='$' ? 2 :1;
                    mapvalue.push([parseInt(final.pointVTRR,10),typ,parseInt(final.rV,10),isNaN(parseInt(final.cca,10)) ? null : parseInt(final.cca,10)]);
                  });
          
                    cet1.coupon_brackets=mapvalue;
                    cet1.ratio_multiplier=parseInt(value.pointsvalue,10);
                    cet1.point_earned_time_delay=parseInt(value.earnetdelay,10);
                    cet1.coupon_expiry_days=parseInt(value.days,10)
                  return t;
 
         } else if(type==='CET2'){

                const Qualifying = this.state.Qualifying[0];
              
                t.coupon_rule_method=2; 
                t.is_disabled= this.state.isDisabled;
                const typ = Qualifying.type==='$' ? 2 :1;
                  t.coupon_rule_settings={
                  CET2:{}
                  };
                        const cet2:any = t.coupon_rule_settings.CET2
                        
                        cet2.coupon_cap_amount=isNaN(parseInt(Qualifying.ccp,10)) ? null : parseInt(Qualifying.ccp,10);
                        cet2.coupon_expiry_days=isNaN(parseInt(value.expirydays,10)) ? null : parseInt(value.expirydays,10);
                        cet2.coupon_type= typ;
                        cet2.coupon_value= parseInt(Qualifying.rV,10);
                        cet2.minimum_purchase= parseInt(Qualifying.mas,10);
                        cet2.number_of_transactions= parseInt(Qualifying.nOT,10);
                        return t
 
         }               
       }else{
         return t
       }       
     });
           
   
     
   const data = new FormData();
   const busienssForupdate=  newBusienss[index];
 
     if(type==='CET1'){
      busienssForupdate.order!==2 ?  data.append("business_uid", busienssForupdate.business_uid.toString()) : console.log('okay');
      data.append("texn_business_uid", busienssForupdate.texn_business_uid.toString());
       data.append("coupon_rule_method",  busienssForupdate.coupon_rule_method.toString());
       data.append("business_name", busienssForupdate.business_name.toString());
       data.append("s3_ids",JSON.stringify(busienssForupdate.s3_ids));
       data.append("is_disabled",busienssForupdate.is_disabled!==undefined && busienssForupdate.is_disabled!==null ? busienssForupdate.is_disabled.toString(): "0");
       data.append("coupon_rule_settings", JSON.stringify(busienssForupdate.coupon_rule_settings));
   
     }else if(type==='CET2'){
      busienssForupdate.order!==2 ?  data.append("business_uid", busienssForupdate.business_uid.toString()) : console.log('okay');
      data.append("texn_business_uid", busienssForupdate.texn_business_uid.toString());
       data.append("coupon_rule_method",  busienssForupdate.coupon_rule_method.toString());
       data.append("business_name", busienssForupdate.business_name.toString());
       data.append("s3_ids",JSON.stringify(busienssForupdate.s3_ids));
       data.append("is_disabled",busienssForupdate.is_disabled!==undefined && busienssForupdate.is_disabled!==null ? busienssForupdate.is_disabled.toString(): "0");
       data.append("coupon_rule_settings", JSON.stringify(busienssForupdate.coupon_rule_settings));
     }
  const $this= this;
   const xhr = new XMLHttpRequest();
   xhr.addEventListener("readystatechange",function(){       
     if (this.readyState === 4) {
      console.log(this.responseText);
      
      $this.setState({loading:false});  

      business.map((tt:any,i:any)=>{
        if(tt.business_uid===Id){
          index = i;
          tt.name=tt.business_name;
          return tt
        }});

      //  $this.clearCET1CET2(1);
      alert('Successfully Updated')
     }
   });
   
   xhr.open("POST", urls.pointsystem+"p1s5e7_update_business");
   xhr.setRequestHeader("cache-control", "no-cache");
   
   xhr.send(data);


 console.log('update',index,newBusienss);
 
 
 } 




 
//  public submitFormFinalt=async(ty:any,value:any)=>{
 
// const data = new FormData();

//   if(ty==='CET1'){
//     data.append("texn_business_uid", "LriACjPIxMjKW9W4j5X");
//     data.append("coupon_rule_method", "1");
//     data.append("business_name", "dev-tet19");
//     data.append("s3_ids", "[1010]");
//     data.append("is_disabled", "0");
//     data.append("coupon_rule_settings", '{"CET1":{"coupon_expiry_days":0,"coupon_type":2,"point_earned_time_delay":0,"ratio_multiplier":25,"coupon_brackets":[[1030,2,103,null]]}}');

//   }else{
//     data.append("texn_business_uid", "LriACjPIxMjKW9W4j5X");
//     data.append("coupon_rule_method", "1");
//     data.append("business_name", "dev-tet19");
//     data.append("s3_ids", "[1010]");
//     data.append("is_disabled", "0");
//     data.append("coupon_rule_settings", '{"CET2":{"coupon_expiry_days":0,"coupon_type":2,"coupon_value":0,"minimum_purchase":25,"coupon_cap_amount":"null",number_of_transactions:"4",');
//   }

// const xhr = new XMLHttpRequest();

// xhr.addEventListener("readystatechange", function () {
//   if (this.readyState === 4) {
//     console.log(this.responseText);
//   }
// });

// xhr.open("POST", "https://p1s5-dot-points-coupon-system.uc.r.appspot.com/p1s5e7_update_business");
// xhr.setRequestHeader("cache-control", "no-cache");

// xhr.send(data);

// } 

public clearCET1CET2=async(arg:any)=>{

  const ResetState ={   
    earnetdelay:'',
    apiKey:'',
    generateKey:'',
    businessname:'',
    pointsvalue:'',
    expirydays:'',
    tierDivs: [
        {
            'pointVTRR':'',
            'rV':'',
            'type':'$',
            'cca':''
        }
    ],
    Qualifying:[
        {
            'nOT':'',
            'mas':'',
            'rV':'',
            'type':'$',
            'ccp':'',
  
        }
    ],
    msg:'',
    days:365,
  }
   this.setState(ResetState);
   if(arg===1){
    this.setState({selectedBusinessId:'',business:[]});
   this.GetBusiness();
   }
   return true;
  }
}

export default Points;