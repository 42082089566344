
import React, { useEffect, useState }  from 'react';
import { Button, InputGroup, Label } from '@blueprintjs/core';
import { urls } from '../global/firebase';

 const Pointsystemquery=()=>{

    const [key,setkey]=useState('');
    const [mobile,setmobile]=useState('');
    const [url,seturl]=useState('');
    const [busdaata,setbusdaata]=useState([] as any);
    const [bool,setbool]=useState(false);
    useEffect(() => {
      if(!bool){
        setbool(true)
        p1s5e6_get_businesses()
      }
 
    },[busdaata])

const p1s5e6_get_businesses=async()=>{

   const requestOptions:any = {
    method: 'POST',
    redirect: 'follow'
  };

  const userState = localStorage.getItem('_auth_state');
  const user = userState!==null ?   JSON.parse(userState).accountData:null;
 await fetch(urls.pointsystem+'p1s5e6_get_businesses', requestOptions)
    .then((response) => response.json())
    .then((result) => {
      const arrx:any= [];
      if(user!==null && userState!==undefined && userState!==null){        
       Object.keys(result).forEach((k,i) => {            
        if((user.permissions.businesses.includes(result[k].texn_business_uid) || user.permissions.businesses.length<=0) && result[k].is_disabled===0){
         
          arrx.push(result[k])         

        }
      });    
      

   
      
      setbusdaata(arrx);
      
    }
    
    });



  }

const openwin=()=>{    
  let con = '';
  try {

  busdaata.map((x:any)=>{
   const  keyx = key.split('_')[1];
   if(x.s3_ids[0]!==undefined && x.s3_ids[0]===keyx){

if(x.coupon_rule_settings!==undefined && x.coupon_rule_settings.CET1!==undefined && x.coupon_rule_settings.CET1.coupon_brackets!==undefined){
  x.coupon_rule_settings.CET1.coupon_brackets.map((r:any)=>{

    con+=`${r[2]}% - ${r[0]} Points,`


  });
}
   }

  })
      
} catch (error) {
    console.log(error);
    
}
  if(mobile!==''){
  const  url = `/customerInfo?yard_api_key=${key}&consumer_phone_number=${mobile}&val=${con}`;
  if(key=='' || key.length>20){
   alert('please enter valid key')
  }else if(mobile=='' || mobile.length<10 || mobile.length>10){
    alert('please enter valid mobile number');
  }else{
    seturl(url)
  
  }
}else{

  const  url = `/LocationCouponData?yard_api_key=${key}&val=${con}`;
  if(key=='' || key.length>20){
   alert('please enter valid key')
  }else{
    seturl(url)
  
  }


}

  return true;


}
    return(
      <div className="main_div_report" >
            
            <br/>
            <br/>
            
         <div>
            <h1>Point System Dashboard</h1>
            <Label>Businesses * <br/>
            <select name="name" id="ifd" onChange={(e:any)=>{ setkey(e.target.value)}}>
              <option value={''}>Select Business</option>
              { busdaata.map((rz:any)=>
                  <option value={'S3Crush_'+rz.s3_ids[0]}>{rz.name}</option>
              )}
            </select>
            </Label>
           
            <Label>Mobile (If you need specific user info)<br/>
            <InputGroup type={'number'}  max={12} onChange={(e:any)=>{setmobile(e.target.value)}}  value={mobile}  />
            </Label>
            
            <Button disabled={key==''}  intent='success' onClick={()=>openwin()}>Continue</Button>
         </div>
<br/>

       <iframe height={600} width={'100%'} src={url}>
        
        </iframe> 
        </div>
    )
}
export default Pointsystemquery